import ApiService from './api.service';

const serviceURL = 'reproposal';
const ReProposalService = {
	/**
	 * Acquire Proposal by projectID
	 * @param {Integer} projectId
	 * @returns {Object} Schedule details
	 */
	get(projectId) {
		return ApiService.get(`${serviceURL}`, `${projectId}`);
	},

	/**
	 * Create new proposal record
	 * @param {Object} body {doc_content: {proposal Object}}
	 * @returns {Object} include success message and record ID
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 * Update proposal content
	 * @param {Integer} proposalId: Proposal ID
	 * @param {Object} contents:
	 * @returns
	 */
	update(proposalId, contents) {
		let body = {
			doc_content: contents,
		};
		console.log(body);
		return ApiService.put(`${serviceURL}/${proposalId}`, body);
	},

	/**
	 * Update bulk proposal records
	 * @param {Array} body [proposals Array]
	 * @returns {Object} Success message
	 */
	updateBulk(contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.put(`${serviceURL}/bulk`, body);
	},

	/**
	 * confirm proposal content
	 * @param {Integer} projectId: Project ID
	 * @returns
	 */
	confirm(projectId) {
		return ApiService.put(`${serviceURL}/${projectId}/confirm`);
	},

	/**
	 * Delete proposal record
	 * @param {Object} body {doc_content: [proposal content]}
	 * @returns {Object} Success message
	 */
	delete(proposalId, body) {
		return ApiService.deleteBulk(`${serviceURL}/${proposalId}`, body);
	},
};

export default ReProposalService;
