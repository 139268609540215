import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import CreateEditItem from '@/components/CreateEditItem/CreateEditItem.vue';
import ItemList from '@/components/ItemList/ItemList.vue';
import CountDown from '@/components/CountDown/CountDown.vue';
import OptionMenu from '@/components/OptionsMenu/OptionMenu.vue';

import socketClient from '@/services/SOCKET';
import TransferService from '@/services/API/transfer.service';
import ReProposalGeneralService from '@/services/API/reproposal_general.service';
import ReProposalService from '@/services/API/reproposal.service';

import CommonConst from '@/constants/CommonConst';
import { getFileExtention, getFileName } from '@/store/modules/File';
import ProposalConst from '@/constants/ProposalConst';

import moment from 'moment';
import { mapState } from 'vuex';

import downloadPdfMixin from '../../../mixin/downloadPdfMixin';

const SAVED_NUMBER = 1;
const PROPSAL_MODE_NUMB = 0;
const REVISION_MODE_NUMB = 1;
const DEFAULT_ITEM = {
	title: undefined,
	itemId: undefined,
	commentContent: undefined,
	gridCols: undefined,
	gridStatus: undefined,
	imageUrl: undefined,
	isOpen: false,
};
export default {
	name: 'ReProposalScreen',
	mixins: [downloadPdfMixin],
	components: {
		ScreenHeader,
		ProjectDeadline,
		CreateEditItem,
		ItemList,
		CountDown,
		OptionMenu,
	},
	data() {
		return {
			screenIcon: 'proposal-icon',
			screenName: '',
			selectProposal: {},
			proposalList: [],
			proposalData: {},
			expiration: 31536000,
			isCheck: false,
			directLink: 'delivery',
			addcostDirectLink: 'addcost',
			middleContent: ' 様 ／ ',
			showItemList: false,
			isOpen: false,
			showFullOptionMenu: false,
			showOptionMenu: false,
			selectedHour: '',
			selectedMinute: '',
			reProposalStatus: '',
			screenMode: '',
			showAnnoucement: false,
			revisionFlag: null,

			pdfPreviewer: {
				url: '',
			},
		};
	},
	computed: {
		// Get projectId. userId from store
		...mapState(['projectId', 'userId', 'projectInfo', 'clientInfo', 'schedule']),
	},
	methods: {
		onDownloadPdfFileFromParent(pdfUrl) { 
			this.pdfPreviewer = pdfUrl
			this.onDownloadPdfFile();
		},
		async onSelect(data) {
			const proposalList = [...this.proposalList];
			proposalList.forEach((item) => {
				if (item.itemId === data['id']) {
					if (item.isOpen) {
						item.isOpen = false;
					} else {
						item.isOpen = true;
					}
				}
			});
		},

		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: green');
				let imgUrl = response.data.link;
				return imgUrl;
			} catch (error) {
				console.log(error);
			}
		},

		async _getReProposalData(projectId) {
			let reProposalResponse = await ReProposalService.get(projectId);
			if (reProposalResponse.status !== 200) {
				throw 'Get proposal failed';
			}

			this.proposalData = reProposalResponse.data;
			this.proposalList = await this._filterProposalList(this.proposalData);
		},

		async _getReProposalGeneralData(projectId) {
			try {
				let response = await ReProposalGeneralService.get(projectId);
				if (response.status !== 200) {
					throw 'Get proposal general failed';
				}

				this.selectedHour = response.data.hours ? response.data.hours.toString() : '';
				this.selectedMinute = response.data.minutes
					? response.data.minutes.toString()
					: '';
				this.screenMode = response.data.screen_mode;
				this._checkScreenMode(this.screenMode);
				this.reProposalStatus = response.data.status;
				this.revisionFlag = response.data?.revision_flag;
				if (this.revisionFlag === ProposalConst.REVISION_DISABLE_NUM) {
					this.showFullOptionMenu = false;
				}

				this._checkReProposalStatus(this.reProposalStatus);

				this._checkNoObjectRevision();
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		async _getAllReProposalData(projectId) {
			await this._getReProposalData(projectId);
			await this._getReProposalGeneralData(projectId);
		},

		_checkNoObjectRevision() {
			if (
				this.screenMode === REVISION_MODE_NUMB &&
				this.reProposalStatus === CommonConst.SCREEN_STATUS.REVISION
			) {
				this.showAnnoucement = false;
				this.showItemList = true;
			}
		},

		async _filterProposalList(proposalDatas) {
			let proposalList = [];
			proposalDatas.forEach(async (data) => {
				let defaultProposal = { ...DEFAULT_ITEM };
				defaultProposal['gridCols'] = 2;
				defaultProposal['gridStatus'] = 'normal';
				defaultProposal['itemId'] = data['id'];
				defaultProposal['nonfilterDateData'] = data['date_created'];
				defaultProposal['title'] = this._renameItem(
					data['title'],
					data['date_created']
				);
				defaultProposal['imageUrl'] = data['image_file_name']
					? await this._getPreviewImgUrl(data['image_file_name'], this.expiration)
					: '';
				defaultProposal['commentContent'] = data['comment'];
				defaultProposal['fileName'] = getFileName(data['image_file_name']);
				defaultProposal['fileType'] = getFileExtention(data['image_file_name']);
				proposalList.push(defaultProposal);
			});
			return proposalList;
		},

		_filterCreatedDate(date) {
			let momentDate = moment(date);
			let year = momentDate.format('YYYY');
			let month = momentDate.format('MM');
			let day = momentDate.format('DD');
			return `${year} 年 ${month} 月 ${day}日に提案`;
		},

		_renameItem(title, date) {
			if (title && date) {
				let momentDate = moment(date);
				let year = momentDate.format('YYYY');
				let month = momentDate.format('MM');
				let day = momentDate.format('DD');
				return `「${year}年${month}月${day}日」: ${title}`;
			} else {
				return title;
			}
		},

		/**
		 * Filter saved proposal items
		 * @param {Array} proposals Proposals Array from database
		 * @returns Saved proposal items
		 */
		_filterSavedItem(proposals) {
			let savedProposal = proposals.filter((item) => item['is_save'] === SAVED_NUMBER);
			return savedProposal;
		},

		_checkReProposalStatus(status) {
			console.log('CHECK REPROPOSAL STATUS', status);
			if (status === '' || status === undefined) {
				this.showAnnoucement = true;
				this.showItemList = false;
			} else if (status === CommonConst.SCREEN_STATUS.MENU_OPEN) {
				this.showOptionMenu = true;
				this.showItemList = true;
			} else if (status === CommonConst.SCREEN_STATUS.FINISH) {
				this.showOptionMenu = false;
				this.revisionDeadlineShow = false;
				this.showItemList = true;
			} else if (status === CommonConst.SCREEN_STATUS.REVISION) {
				this.showOptionMenu = false;
			} else if (status === CommonConst.SCREEN_STATUS.SEND) {
				this.showAnnoucement = false;
				this.showItemList = true;
			}
		},

		removeQuestion(questionId) {
			this.proposalList = this.proposalList.filter(
				(proposal) => proposal.itemId !== questionId
			);
		},

		_checkScreenMode(screenMode) {
			if (screenMode === '' || screenMode === undefined) {
				console.log('CHECK SCREEN MODE UNDEFINED');
			} else if (screenMode == PROPSAL_MODE_NUMB) {
				this.screenName = CommonConst.RE_PROPOSAL_SCREEN_NAME;
				this.showFullOptionMenu = true;
			} else if (screenMode == REVISION_MODE_NUMB) {
				this.screenName = CommonConst.RE_REVISION_SCREEN_NAME;
				this.showFullOptionMenu = false;
			}
		},

		listenerDeleteReproposalQuestion: function (data) {
			if (data.event_name === 'delete_reproposal_question') {
				this.removeQuestion(data.content);
			}
		},
	},

	async created() {
		socketClient.listen('new_proposal_updated', async (data) => {
			if (data.status) {
				this.$store.commit('setIsAppProcessing', true);

				await this._getAllReProposalData(this.projectId);

				if (data.newName) {
					this.$emit(
						'on-change-proposal-name',
						data.newName,
						CommonConst.SCREEN_ID.REPROPOSAL
					);
				}

				this.$store.commit('setIsAppProcessing', false);
			}
		});

		socketClient.listen('open_readonly_option_menu', (data) => {
			console.log('open readonly option menu');
			if (data.isOpen) {
				this.showOptionMenu = true;
				this.selectedHour = data.hourSelected.toString();
				this.selectedMinute = data.minuteSelected.toString();
			}
		});

		socketClient.listen('new_data_transfer', this.listenerDeleteReproposalQuestion);
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		await this._getAllReProposalData(this.projectId);

		this.$store.commit('setIsAppProcessing', false);
	},

	beforeDestroy() {
		socketClient.removeAllListeners('open_readonly_option_menu');

		socketClient.removeListener(
			'new_data_transfer',
			this.listenerDeleteReproposalQuestion
		);
	},
};
